import React from 'react';
import {IonButton, IonHeader, IonContent, IonNavLink, IonToolbar, IonTitle} from '@ionic/react';

import Cookies from './cookies';
import Legal from "./legal";
import Privacidad from "./privacidad";
import Terminos from "./terminos_uso";

function Info() {
    return (<>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Acerca de BuscaPK</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent className="ion-text-justify ion-padding">
            <h2>¿Qué es BuscaPK?</h2>
            <p>Es una aplicación diseñada para localizar de forma rápida y efectiva un punto kilométrico en cualquier
                carretera de España. Además también permite conocer el punto kilométrico que se encuentra más cercano a
                tu ubicación.
            </p>
            <h2>¿Cuáles son sus funcionalidades?</h2>
            <ol>
                <li>
                    Permite a los equipos de primera respuesta cubrir una necesidad no satisfecha hasta el momento:
                    disponer de la ubicación geográfica del punto kilométrico en el que se localiza la emergencia.
                </li>
                <li>Si estás involucrado en una situación de emergencia, te permite conocer cuál es el punto kilométrico
                    más cercano a tu ubicación actual. De este modo podrás proporcionar también de manera rápida y
                    efectiva tu localización a los servicios de emergencia.
                </li>
            </ol>
            <h2>¿Por qué es importante BuscaPK?</h2>
            <p>Porque permite reducir el tiempo de respuesta a una emergencia.</p>
            <p>La rapidez con la que se brinde atención médica adecuada puede marcar la diferencia entre la vida y la
                muerte, así como influir en el grado de recuperación de la persona. Por ello, es importante que los
                equipos de emergencia puedan llegar al lugar de los hechos lo antes posible.
            </p>
            <h2>Soporte</h2>
            <p>Notifícanos cualquier error que encuentres. Esta aplicación se mantiene actualizada en
                gran parte gracias a la colaboración de los usuarios.</p>
            <div className="centered-content">
                <IonButton href="mailto:info@buscapk.es" subject="Incidencia%20con%20la%20aplicación"
                           target="_blank">Notifica
                    una incidencia </IonButton>
            </div>
            <p>¿Te gusta la app? Puedes colaborar invitándome a un café -> <a href="https://ko-fi.com/buscapk"
                                                                              rel="noreferrer"><img
                src="/kofi.webp" alt="ko-fi" width="22px"/></a>
            </p>
            <p>Puedes seguirnos en Twitter: <a href="https://twitter.com/buscapk">@buscapk</a>
            </p>
            <h2>Créditos</h2>
            <p>Obra derivada de IGR-RT 2023 <a href="https://creativecommons.org/licenses/by/4.0/deed.es">CC-BY 4.0</a> <a href="www.scne.es"> scne.es</a></p>
            <hr/>
            <div className="centered-content">
                <IonNavLink routerDirection="forward" component={() => <Terminos/>}>
                    <IonButton fill="clear">Términos de Uso</IonButton>
                </IonNavLink>
                <IonNavLink routerDirection="forward" component={() => <Cookies/>}>
                    <IonButton fill="clear">Política de Cookies</IonButton>
                </IonNavLink>
                <IonNavLink routerDirection="forward" component={() => <Legal/>}>
                    <IonButton fill="clear">Aviso Legal</IonButton>
                </IonNavLink>
                <IonNavLink routerDirection="forward" component={() => <Privacidad/>}>
                    <IonButton fill="clear">Política de Privacidad</IonButton>
                </IonNavLink>
            </div>
        </IonContent>
    </>);
}

export default Info;
