import React, {useState, useEffect, useCallback, useMemo} from "react";
import {Map, Marker, Overlay} from "pigeon-maps";
import writeToClipboard from "../utils/writeToClipboard";
import {IonButton} from "@ionic/react";
import {t} from "i18next";

const PostsMap = ({items, onBoundsChanged, center, userLocation}) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [mapBounds, setMapBounds] = useState(null);
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth, height: window.innerHeight,
    });


    const handleResize = useCallback(() => {
        setWindowDimensions(prevDimensions => ({
            ...prevDimensions,
            width: window.innerWidth,
            height: window.innerHeight,
        }));
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    const markers = useMemo(() => items.map((resultado) => {
        const [lng, lat] = resultado.location
            .replace("SRID=4326;POINT (", "")
            .replace(")", "")
            .split(" ")
            .map(parseFloat);

        const markerClass = `mapmarker${resultado.road.hierarchy}`;

        return (
            <Marker
                className={markerClass}
                key={resultado.id}
                anchor={[lat, lng]}
                color="#ff0000"
                offset={[0, -20]}
                onClick={() => {
                    if (selectedMarker && selectedMarker.id === resultado.id) {
                        setSelectedMarker(null);
                    } else {
                        setSelectedMarker({...resultado, coords: [lat, lng]});
                    }
                }}
            >
                <div>{resultado.kilometer}</div>
            </Marker>
        );
    }), [items, selectedMarker]);

    const overlay = useMemo(() => {
        if (!selectedMarker) {
            return null;
        }

        return (<Overlay className="overlay-content" anchor={selectedMarker.coords} offset={[55, -15]}>
            <div>
                <div className="overlay-head">{selectedMarker.road.name},
                    km {selectedMarker.kilometer}</div>
                <div className="overlay-close" id="btnCerrarOverlay" onClick={() => setSelectedMarker(null)}>
                    <span>X</span>
                </div>
                <hr></hr>
                <div style={{marginTop: -8}}>
                    <a title="Google Maps"
                       href={`https://maps.google.com/maps?ll=${selectedMarker.coords[0]},${selectedMarker.coords[1]}=0.1,0.1&t=m&q=${selectedMarker.coords[0]},${selectedMarker.coords[1]}`}
                       target="_blank" rel="noopener noreferrer">
                        <img src="/google-maps-144.webp" alt="Google Maps"
                             style={{width: '42px', height: '42px'}}/></a>
                    <a title="Apple Maps"
                       href={`https://maps.apple.com/maps?ll=${selectedMarker.coords[0]},${selectedMarker.coords[1]}=0.1,0.1&t=m&q=${selectedMarker.coords[0]},${selectedMarker.coords[1]}`}
                       target="_blank" rel="noopener noreferrer">
                        <img src="/mapa-de-apple-144.webp" alt="Apple Maps"
                             style={{width: '42px', height: '42px'}}/></a>
                    <a title="Waz"
                       href={`http://waze.com/ul?=${selectedMarker.coords[0]},${selectedMarker.coords[1]}=0.1,0.1&t=m&q=${selectedMarker.coords[0]},${selectedMarker.coords[1]}`}
                       target="_blank" rel="noopener noreferrer">
                        <img src="/waze-144.webp" alt="Waze"
                             style={{width: '42px', height: '42px'}}/></a>
                </div>
                              <IonButton
                    size="small" color="light" fill="outline" className="centered-content"
                    onClick={()=>writeToClipboard(selectedMarker.location)}>{t('misc.copy')}</IonButton>
            </div>
        </Overlay>);
    }, [selectedMarker]);

    const handleBoundsChanged = useCallback((center, zoom, bounds, marginBounds) => {
        setMapBounds(bounds);
        onBoundsChanged(center, zoom, bounds, marginBounds);
    }, [onBoundsChanged]);

    return (<Map
        center={center}
        defaultZoom={13}
        zoomSnap={false}
        width={windowDimensions.width}
        height={windowDimensions.height}
        onBoundsChanged={handleBoundsChanged}
        animateMaxScreens={10}
    >
        {markers}
        {overlay}
        {userLocation && (
            <Marker className="usermarker" anchor={userLocation} offset={[0, -20]}> </Marker>
        )}
    </Map>);
};

export default PostsMap;