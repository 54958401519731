import React from 'react';
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonNavLink,
    IonButton
} from '@ionic/react';
import Info from "./info";

function Terminos() {
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle>Términos de Uso</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-text-justify ion-padding">
                <h2>Términos de Uso</h2>
                <hr/>
                <p>La utilización de este servicio está sujeta a estos términos y condiciones.</p>
                <p>Intentamos conseguir la máxima precisión posible en las ubicaciones de los puntos kilométricos, pero
                    las señales
                    de los mismos pueden caerse, moverse o ser retiradas.</p>
                <p>Esta aplicación pretende ser una ayuda, no proporcionar una
                    ubicación exacta de los puntos kilométricos. Hay que tener en cuenta que si nos encontramos en una
                    zona con
                    varias carreteras próximas, el punto kilométrico más cercano puede no pertenecer a la carretera en
                    la que se encuentre el usuario.</p>
                <p>La ubicación del usuario se toma al cargar la aplicación, y no se actualiza en tiempo real.
                    Para poder actualizarla es necesario pulsar el botón que se encuentra en la parte inferior derecha
                    del mapa.
                    De este modo el usuario se puede desplazar libremente sin perder la referencia deseada. Esto es útil
                    si, por
                    ejemplo, somos testigos de un accidente pero no podemos detenernos en ese momento.</p>
                <p>Por favor, infórmanos sobre cualquier error o deficiencia que encuentres en la aplicación. La
                    aplicación
                    se mantiene actualizada gracias a los aportes de los usuarios. La dirección de contacto es <a
                        href="mailto:info@buscapk.es" target="_blank" rel="noreferrer">info@buscapk.es</a></p>
                <p>La aplicación se proporciona tal cual, sin garantía de ningún tipo, expresa o implícita,
                    incluyendo, pero no limitándose a las garantías de comerciabilidad, idoneidad para un propósito
                    particular y no infracción. En ningún caso los autores o titulares de los derechos de autor serán
                    responsables de reclamaciones, daños u otras responsabilidades, ya sea en virtud de un contrato,
                    agravio u otra teoría legal, surgidos de, relacionados con o derivados de esta aplicación o el uso u
                    otros tratos en esta aplicación.</p>
                <IonNavLink routerDirection="forward" component={() => <Info/>}>
                    <IonButton>Volver a la página de información</IonButton>
                </IonNavLink>
            </IonContent>
        </>
    );
}

export default Terminos;