import React from 'react';
import {
    IonBackButton, IonButtons, IonButton, IonHeader, IonContent, IonNavLink, IonToolbar, IonTitle,
} from '@ionic/react';

import Info from "./info";

function Cookies() {
    return (<>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton></IonBackButton>
                </IonButtons>
                <IonTitle>Cookies</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent className="ion-text-justify ion-padding">
                <h1>Política de Cookies</h1>
                <hr/>
                        <h2>¿Qué son las cookies?</h2>

                        <p>
                            En inglés, el término &quot;cookie&quot; significa galleta, pero en el ámbito de la
                            navegación web, una &quot;cookie&quot; es algo completamente distinto. Cuando accede a
                            nuestro Sitio Web, en el navegador de su dispositivo se almacena una pequeña cantidad de
                            texto que se denomina &quot;cookie&quot;. Este texto contiene información variada sobre
                            su navegación, hábitos, preferencias, personalizaciones de contenidos, etc...
                        </p>

                        <p>
                            Existen otras tecnologías que funcionan de manera similar y que también se usan para
                            recopilar datos sobre tu actividad de navegación. Llamaremos &quot;cookies&quot; a todas
                            estas tecnologías en su conjunto.
                        </p>

                        <p>
                            Los usos concretos que hacemos de estas tecnologías se describen en el presente
                            documento.
                        </p>

                        <h2>¿Para qué se utilizan las cookies en esta web?</h2>

                        <p>
                            Las cookies son una parte esencial de cómo funciona el Sitio Web. El objetivo principal
                            de nuestras cookies es mejorar su experiencia en la navegación. Por ejemplo, para
                            recordar sus preferencias (idioma, país, etc.) durante la navegación y en futuras
                            visitas. La información recogida en las cookies nos permite además mejorar la web,
                            adaptarla a sus intereses como usuario, acelerar las búsquedas que realice, etc..
                        </p>

                        <p>
                            En determinados casos, si hemos obtenido su previo consentimiento informado, podremos
                            utilizar cookies para otros usos, como por ejemplo para obtener información que nos
                            permita mostrarle publicidad basada en el análisis de sus hábitos de navegación.
                        </p>

                        <h2>¿Para qué NO se utilizan las cookies en esta web?</h2>

                        <p>
                            En las cookies que utilizamos no se almacena información sensible de identificación
                            personal como su nombre, dirección, tu contraseña, etc...
                        </p>

                        <h2>¿Quién utiliza la información almacenada en las cookies?</h2>

                        <p>
                            La información almacenada en las cookies de nuestro Sitio Web es utilizada
                            exclusivamente por nosotros, a excepción de aquellas identificadas más adelante
                            como &quot;cookie de terceros&quot;, que son utilizadas y gestionadas por entidades
                            externas que nos proporcionan servicios que mejoran la experiencia del usuario. Por
                            ejemplo las estadísticas que se recogen sobre el número de visitas, el contenido que más
                            gusta, etc... lo suele gestionar Google Analytics.
                        </p>


                        <h2>¿Cómo puede evitar el uso de cookies en este Sitio Web?</h2>

                        <p>
                            Si prefiere evitar el uso de las cookies, puede RECHAZAR su uso o puede CONFIGURAR las
                            que quiere evitar y las que permite utilizar (en este documento le damos información
                            ampliada al respecto de cada tipo de cookie, su finalidad, destinatario, temporalidad,
                            etc... ).
                        </p>

                        <p>
                            Si las ha aceptado, no volveremos a preguntarle a menos que borre las cookies en su
                            dispositivo según se indica en el apartado siguiente. Si quiere revocar el
                            consentimiento tendrá que eliminar las cookies y volver a configurarlas.
                        </p>


                        <h2>¿Cómo deshabilito y elimino la utilización de cookies?</h2>


                        <p>
                            Para restringir, bloquear o borrar las cookies de este Sitio Web (y las usada por
                            terceros) puede hacerlo, en cualquier momento, modificando la configuración de su
                            navegador. Tenga en cuenta que esta configuración es diferente en cada navegador.
                        </p>

                        <p>
                            En los siguientes enlaces encontrará instrucciones para habilitar o deshabilitar las
                            cookies en los navegadores más comunes.
                        </p>

                        <p>
                            <ul>
                                <li><a rel="noopener noreferrer nofollow" target="_blank"
                                       href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">Firefox</a>
                                </li>
                                <li><a target="_blank" rel="noopener noreferrer nofollow"
                                       href="https://support.google.com/chrome/answer/95647?hl=es">Google Chrome</a>
                                </li>
                                <li><a target="_blank" rel="noopener noreferrer nofollow"
                                       href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=&quot;ie-10">Internet
                                    Explorer</a></li>
                                <li><a target="_blank" rel="noopener noreferrer nofollow"
                                       href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy">Microsoft
                                    Edge</a></li>
                                <li><a rel="noopener noreferrer nofollow" target="_blank"
                                       href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">Safari</a></li>
                            </ul>
                        </p>


                        <h2>¿Qué tipos de cookies se utilizan en esta página web?</h2>

                        <p>
                            Cada página web utiliza sus propias cookies. En nuestra web utilizamos las que se
                            indican a continuación:
                        </p>

                        <p>
                            <b>SEGÚN LA ENTIDAD QUE LO GESTIONA</b>
                        </p>

                        <p>
                            <b>Cookies propias:</b>
                        </p>

                        <p>
                            Son aquellas que se envían al equipo terminal del Usuario desde un equipo o dominio
                            gestionado por el propio editor y desde el que se presta el servicio solicitado por el
                            Usuario.
                        </p>

                        <p>
                            <b>Cookies de terceros:</b>
                        </p>

                        <p>
                            Son aquellas que se envían al equipo terminal del Usuario desde un equipo o dominio que
                            no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos
                            través de las cookies.
                        </p>

                        <p>
                            En el caso de que las cookies sean servidas desde un equipo o dominio gestionado por el
                            propio editor, pero la información que se recoja mediante estas sea gestionada por un
                            tercero, no pueden ser consideradas como cookies propias si el tercero las utiliza para
                            sus propias finalidades (por ejemplo, la mejora de los servicios que presta o la
                            prestación de servicios de carácter publicitario a favor de otras entidades).
                        </p>

                        <p>
                            <b>SEGÚN SU FINALIDAD</b>
                        </p>

                        <p>
                            <b>Cookies técnicas:</b>
                        </p>

                        <p>
                            Son aquellas necesarias para la navegación y el buen funcionamiento de nuestro Sitio
                            Web, como por ejemplo, controlar el tráfico y la comunicación de datos, identificar la
                            sesión, acceder a partes de acceso restringido, realizar la solicitud de inscripción o
                            participación en un evento, contar visitas a efectos de la facturación de licencias del
                            software con el que funciona el servicio del Sitio Web, utilizar elementos de seguridad
                            durante la navegación, almacenar contenidos para la difusión de vídeos o sonido,
                            habilitar contenidos dinámicos (por ejemplo, animación de carga de un texto o imagen) o
                            compartir contenidos a través de redes sociales.
                        </p>

                        <p>
                            <b>Cookies de análisis:</b>
                        </p>

                        <p>
                            Permiten cuantificar el número de usuarios y así realizar la medición y análisis
                            estadístico de la utilización que hacen los usuarios del Sitio Web.
                        </p>

                        <p>
                            <b>Cookies de preferencias o personalización:</b>
                        </p>

                        <p>
                            Son aquellas que permiten recordar información para que el Usuario acceda al servicio
                            con determinadas características que pueden diferenciar su experiencia de la de otros
                            usuarios, como, por ejemplo, el idioma, el número de resultados a mostrar cuando el
                            Usuario realiza una búsqueda, el aspecto o contenido del servicio en función del tipo de
                            navegador a través del cual el Usuario accede al servicio o de la región desde la que
                            accede al servicio, etc.
                        </p>


                        <p>
                            <b>SEGÚN EL PLAZO DE TIEMPO QUE PERMANECEN ACTIVADAS</b>
                        </p>

                        <p>
                            <b>Cookies de sesión:</b>
                        </p>

                        <p>
                            Son aquellas diseñadas para recabar y almacenar datos mientras el Usuario accede a una
                            página web.
                        </p>

                        <p>
                            Se suelen emplear para almacenar información que solo interesa conservar para la
                            prestación del servicio solicitado por el Usuario en una sola ocasión (por ejemplo, una
                            lista de productos adquiridos) y desaparecen al terminar la sesión.
                        </p>

                        <p>
                            <b>Cookies persistentes:</b>
                        </p>

                        <p>
                            Son aquellas en las que los datos siguen almacenados en el terminal y pueden ser
                            accedidos y tratados durante un periodo definido por el responsable de la cookie, y que
                            puede ir de unos minutos a varios años. A este respecto debe valorarse específicamente
                            si es necesaria la utilización de cookies persistentes, puesto que los riesgos para la
                            privacidad podrían reducirse mediante la utilización de cookies de sesión. En todo caso,
                            cuando se instalen cookies persistentes, se recomienda reducir al mínimo necesario su
                            duración temporal atendiendo a la finalidad de su uso. A estos efectos, el Dictamen
                            4/2012 del GT29 indicó que para que una cookie pueda estar exenta del deber de
                            consentimiento informado, su caducidad debe estar relacionada con su finalidad. Debido a
                            ello, es mucho más probable que se consideren como exceptuadas las cookies de sesión que
                            las persistentes.
                        </p>
            <IonNavLink routerDirection="forward" component={() => <Info/>}>
                <IonButton>Volver a la página de información</IonButton>
            </IonNavLink>
        </IonContent>
    </>);
}

export default Cookies;