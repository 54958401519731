// LanguageSelector.js
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonAlert, IonIcon, IonLabel, IonTab, IonTabButton} from '@ionic/react';
import {flag, language} from "ionicons/icons";

const LanguageSelector = () => {
    const {t, i18n} = useTranslation();
    const [showLanguageAlert, setShowLanguageAlert] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShowLanguageAlert(false);
    };

    const lngs = {
        en: {nativeName: t('languages.english')},
        es: {nativeName: t('languages.spanish')},
        fr: {nativeName: t('languages.french')},
        pt: {nativeName: t('languages.portuguese')},
    };

    return (
        <>
            <IonLabel>{t('tabs.language')}</IonLabel>
            <IonIcon icon={flag}/>

            <IonAlert
                isOpen={showLanguageAlert}
                trigger='language-alert'
                onDidDismiss={() => setShowLanguageAlert(false)}
                header={t('tabs.selectLanguage')}
                buttons={Object.keys(lngs).map((lng) => ({
                    text: lngs[lng].nativeName,
                    handler: () => changeLanguage(lng)
                }))}
            />
        </>
    );
};

export default LanguageSelector;
