import React from 'react';
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonNavLink,
    IonButton
} from '@ionic/react';
import Legal from "./legal";

function Privacidad() {
    return (<>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton></IonBackButton>
                </IonButtons>
                <IonTitle>Política de Privacidad</IonTitle>
            </IonToolbar>
        </IonHeader>
            <IonContent className="ion-text-justify ion-padding">
                <h2>Política de privacidad</h2>
                <hr/>
                <a href="#"
                   onClick="if(window.__lxG__consent__!==undefined&amp;&amp;window.__lxG__consent__.getState()!==null){window.__lxG__consent__.showConsent()} else {alert('This function only for users from European Economic Area (EEA)')}; return false">Cambiar
                    los ajustes de privacidad</a>
                <p>El Titular le informa sobre su Política de Privacidad respecto del tratamiento y protección de los
                    datos de carácter personal de los usuarios que puedan ser recabados durante la navegación a través
                    del Sitio Web: https://buscapk.es<br/>
                    En este sentido, el Titular garantiza el cumplimiento de la normativa vigente en materia de
                    protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de
                    Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el
                    Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la
                    protección de las personas físicas (RGPD).<br/>
                    El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las condiciones
                    incluidas en el:</p>
                <IonNavLink routerDirection="forward" component={() => <Legal/>}>
                    <IonButton fill="clear">Aviso Legal</IonButton>
                </IonNavLink>
                <h2>Identidad del Responsable</h2>
                <ul>
                    <li>Responsable: Enrique Sánchez-Reboto García.</li>
                    <li>NIF: 08849592C</li>
                    <li>Domicilio: Badajoz, España</li>
                    <li>Coreeo electrónico: info@buscapk.es</li>
                    <li>Sitio Web: https//buscapk.es</li>
                </ul>
                <h2>Principios aplicados en el tratamiento de datos</h2>
                <p>
                    En el tratamiento de sus datos personales, el Titular aplicará los siguientes principios que se
                    ajustan a las exigencias del nuevo reglamento europeo de protección de datos (RGPD):
                </p>
                <ul>
                    <li>
                        Principio de licitud, lealtad y transparencia: El Titular siempre requerirá el
                        consentimiento
                        para el tratamiento de los datos personales que puede ser para uno o varios fines
                        específicos
                        sobre los que el Titular informará al Usuario previamente con absoluta transparencia.
                    </li>
                    <li>
                        Principio de minimización de datos: El Titular solicitará solo los datos estrictamente
                        necesarios para el fin o los fines que los solicita.
                    </li>
                    <li>
                        Principio de limitación del plazo de conservación: El Titular mantendrá los datos
                        personales
                        recabados durante el tiempo estrictamente necesario para el fin o los fines del
                        tratamiento. El
                        Titular informará al Usuario del plazo de conservación correspondiente según la
                        finalidad.
                        <br/>En el caso de suscripciones, el Titular revisará periódicamente las listas y
                        eliminará
                        aquellos registros inactivos durante un tiempo considerable.
                    </li>
                    <li>
                        Principio de integridad y confidencialidad: Los datos personales recabados serán
                        tratados de tal
                        manera que su seguridad, confidencialidad e integridad está garantizada.
                        <br/>El Titular toma las precauciones necesarias para evitar el acceso no autorizado o
                        uso
                        indebido de los datos de sus usuarios por parte de terceros.
                    </li>
                </ul>
                <h2>Obtención de datos personales</h2>
                <p>
                    Para navegar por el sitio Web no es necesario que facilite ningún dato personal.
                </p>
                <p>
                    Los casos en los que usted sí proporciona sus datos personales son los siguientes:
                </p>
                <ul>
                    <li>Al remitir un email a la dirección de contacto.</li>
                </ul>
                <h2>Derechos</h2>
                <p>
                    El Titular le informa que sobre sus datos personales tiene derecho a:
                </p>
                <ul>
                    <li>Solicitar el acceso a los datos almacenados.</li>
                    <li>Solicitar una rectificación o la supresión.</li>
                    <li>Solicitar la limitación de su tratamiento.</li>
                    <li>Oponerse al tratamiento.</li>
                </ul>
                <p>
                    No puede ejercitar el derecho a la portabilidad de los datos.
                </p>
                <p>
                    El ejercicio de estos derechos es personal y por tanto debe ser ejercido
                    directamente por el
                    interesado, solicitándolo directamente al Titular, lo que significa que cualquier
                    cliente,
                    suscriptor o colaborador que haya facilitado sus datos en algún momento, puede
                    dirigirse al Titular
                    y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido,
                    solicitar la
                    rectificación de los mismos, oponerse al tratamiento, limitar su uso o solicitar la
                    supresión de
                    esos datos en los ficheros del Titular.
                </p>
                <p>
                    Para ejercitar sus derechos tiene que enviar su petición en otro email,
                    preferiblemente desde la
                    misma dirección desde la que se envío el anterior email, aunque esto no es
                    obligatorio.
                </p>
                <p>
                    El ejercicio de estos derechos no incluye ningún dato que el Titular esté obligado a
                    conservar con
                    fines administrativos, legales o de seguridad.
                </p>
                <p>
                    Tiene derecho a la tutela judicial efectiva y a presentar una reclamación ante la
                    autoridad de
                    control, en este caso, la Agencia Española de Protección de Datos, si considera que
                    el tratamiento
                    de datos personales que le conciernen infringe el Reglamento.
                </p>

                <h2>Finalidad del tratamiento de datos personales</h2>
                <p>
                    Cuando usted manda un correo al Titular, está facilitando información de carácter
                    personal de
                    la que el responsable es el Titular. Esta información puede incluir datos de
                    carácter personal como
                    pueden ser su dirección, nombre y apellidos, dirección física, dirección de correo
                    electrónico,
                    número de teléfono, y otra información. Al facilitar esta información, da su
                    consentimiento para que
                    su información sea recopilada, utilizada, gestionada y almacenada por Hostalia sólo
                    como se describe
                    en las páginas:
                </p>
                <IonNavLink routerDirection="forward" component={() => <Legal/>}>
                    <IonButton fill="clear">Aviso Legal</IonButton>
                </IonNavLink>
                <br/>
                <IonNavLink routerDirection="forward" component={() => <Privacidad/>}>
                    <IonButton fill="clear">Política de Privacidad</IonButton>
                </IonNavLink>
                <p>
                    Existen otras finalidades por las que el Titular trata datos personales:
                </p>
                <ul>
                    <li>
                        Para garantizar el cumplimiento de las condiciones recogidas en la
                        página de Aviso Legal y de la
                        ley aplicable. Esto puede incluir el desarrollo de herramientas y
                        algoritmos que ayuden al Sitio
                        Web a garantizar la confidencialidad de los datos personales que recoge.
                    </li>
                    <li>
                        Para apoyar y mejorar los servicios que ofrece este Sitio Web.
                    </li>
                    <li>
                        Para analizar la navegación de los usuarios. El Titular recoge otros
                        datos no identificativos
                        que se obtienen mediante el uso de cookies que se descargan en el
                        ordenador del Usuario cuando
                        navega por el Sitio Web cuyas características y finalidad están
                        detalladas en la página de Política de Cookies.
                    </li>
                    <li>
                        Para gestionar las redes sociales. El Titular tiene presencia en
                        redes sociales.
                        Si usted se hace seguidor en las redes sociales del Titular el
                        tratamiento de los datos
                        personales se regirá por este apartado, así como por aquellas
                        condiciones de uso, políticas
                        de privacidad y normativas de acceso que pertenezcan a la red social
                        que proceda en cada
                        caso y que ha aceptado previamente.
                        <br/>
                        Puede consultar las políticas de privacidad de las principales redes
                        sociales en estos
                        enlaces:
                        <ul>
                            <li><a href="https://twitter.com/privacy"
                                   target="_blank" rel="noreferrer">Twitter</a></li>
                        </ul>
                        <p>
                            El Titular tratará sus datos personales con la finalidad de
                            administrar correctamente su
                            presencia en la red social, informarle de sus actividades, así
                            como para cualquier otra
                            finalidad que las normativas de las redes sociales permiten.
                        </p>
                        <p>
                            En ningún caso el Titular utilizará los perfiles de seguidores
                            en redes sociales para enviar publicidad de manera individual.
                        </p>
                    </li>
                </ul>
                <h2>Seguridad de los datos personales</h2>
                <p>
                    Para proteger sus datos personales, el Titular toma todas las precauciones
                    razonables y sigue las
                    mejores prácticas de la industria para evitar su pérdida, mal uso, acceso
                    indebido, divulgación,
                    alteración o destrucción de los mismos.
                </p>
                <p>
                    El sitio Web está alojado en: Linode. La seguridad de los datos está
                    garantizada, ya que
                    toman todas las medidas de seguridad necesarias para ello. Puede consultar
                    su política de privacidad
                    para tener más información.
                </p>
                <p>
                    El Titular informa al Usuario de que sus datos personales no serán cedidos a
                    terceras
                    organizaciones, con la salvedad de que dicha cesión de datos esté amparada
                    en una obligación legal o
                    cuando la prestación de un servicio implique la necesidad de una relación
                    contractual con un
                    encargado de tratamiento. En este último caso, solo se llevará a cabo la
                    cesión de datos al tercero
                    cuando el Titular disponga del consentimiento expreso del Usuario.
                </p>
                <p>
                    Sin embargo, en algunos casos se pueden realizar colaboraciones con otros
                    profesionales, en esos
                    casos, se requerirá consentimiento al Usuario informando sobre la identidad
                    del colaborador y la
                    finalidad de la colaboración. Siempre se realizará con los más estrictos
                    estándares de seguridad.
                </p>
                <h2>Contenido de otros sitios web</h2>
                <p>
                    Las páginas de este sitio Web pueden incluir contenido incrustado (por
                    ejemplo, vídeos, imágenes,
                    artículos, etc.). El contenido incrustado de otras web se comporta
                    exactamente de la misma manera
                    que si hubiera visitado la otra web.
                </p>
                <p>
                    Estos sitios Web pueden recopilar datos sobre usted, utilizar cookies,
                    incrustar un código de
                    seguimiento adicional de terceros, y supervisar su interacción usando este
                    código.
                </p>
                <h2>Política de Cookies</h2>
                Para que este sitio Web funcione correctamente necesita utilizar cookies,
                que es una información que
                se almacena en su navegador web.
                <p>
                    Puede consultar toda la información relativa a la política de recogida y
                    tratamiento de las cookies
                    en la página de Política de
                    Cookies.
                </p>
                <h2>Legitimación para el tratamiento de datos</h2>
                <p>
                    La base legal para el tratamiento de sus datos es:
                </p>
                <ul>
                    <li>
                        La voluntad de comunicarse por un email.
                    </li>
                </ul>
                <h2>Categorías de datos personales</h2>
                <p>
                    Las categorías de datos personales que trata el Titular son:
                </p>
                <ul>
                    <li>Datos identificativos.</li>
                    <li>No se tratan categorías de datos especialmente protegidos.
                    </li>
                </ul>
                <h2>Conservación de datos personales</h2>
                <p>
                    Los datos personales proporcionados al Titular se conservarán
                    hasta que solicite su supresión.
                </p>
                <h2>Destinatarios de datos personales</h2>
                <ul>
                    <li>
                        <b>Google Analytics</b> es un servicio de analítica web
                        prestado por Google, Inc., una
                        compañía de Delaware cuya oficina principal está en 1600
                        Amphitheatre Parkway, Mountain View
                        (California), CA 94043, Estados Unidos
                        (&quot;Google&quot;).
                        <br/>Google Analytics utiliza &quot;cookies&quot;, que
                        son archivos de texto ubicados en tu
                        ordenador, para ayudar al Titular a analizar el uso que
                        hacen los Usuarios del sitio Web. La
                        información que genera la cookie acerca del uso del
                        sitio Web (incluyendo la dirección IP)
                        será directamente transmitida y archivada por Google en
                        los servidores de Estados Unidos.
                        <br/>Más información en:&nbsp;<a
                        href="https://policies.google.com/privacy?hl=es-ES"
                        target="_blank" rel="noreferrer">https://analytics.google.com</a>
                    </li>
                    <li>
                        <b>Google Adsense</b> es un servicio de publicidad
                        prestado por Google, Inc., una
                        compañía de Delaware cuya oficina principal está en 1600
                        Amphitheatre Parkway, Mountain View
                        (California), CA 94043, Estados Unidos
                        (&quot;Google&quot;).
                        <br/>AdSense utiliza las cookies para mejorar la publicidad. Entre otros fines, se
                        utilizan para orientar los anuncios según qué contenido es relevante para los usuarios,
                        mejorar los informes de rendimiento de las campañas y evitar mostrar anuncios que los
                        usuarios ya hayan visto.<br/>
                        Las cookies no contienen información personal identificable. Según la configuración de
                        los editores y de los usuarios, la información asociada a las cookies que se utilizan en
                        publicidad puede añadirse a la cuenta de Google de los usuarios.
                    </li>
                    <li>
                        <b>Ezoic-ads</b> Ezoic es una plataforma de monetización y optimización de anuncios en línea.
                        Ezoic se centra en ayudar a los editores de sitios web a maximizar sus ingresos publicitarios y
                        mejorar la experiencia del usuario mediante la implementación de tecnologías de inteligencia
                        artificial y pruebas multivariables.<br/>
                        Más información en<a href="https://www.ezoic.com/es-lang/willp/"
                                             target="_blank" rel="noreferrer">https://www.ezoic.com</a>
                    </li>
                </ul>
                <p>
                    Puede consultar como Google gestiona la privacidad en lo que
                    respecta al uso de las cookies y otra
                    información en la página de Política de privacidad de
                    Google:&nbsp;<a target="_blank" rel="noreferrer"
                                    href="https://policies.google.com/privacy?hl=es"
                >https://policies.google.com/privacy?hl=es</a>
                </p>

                <p>
                    También puede ver una lista de los tipos de cookies que
                    utiliza Google y sus colaboradores y toda la
                    información relativa al uso que hacen de las cookies
                    publicitarias en:
                </p>
                <ul>
                    <li><a target="_blank" rel="noreferrer"
                           href="https://policies.google.com/technologies/types?hl=es"
                    >Tipos de cookies que utiliza Google</a></li>
                    <li><a target="_blank" rel="noreferrer"
                           href="https://policies.google.com/technologies/ads?hl=es">Cómo
                        utiliza Google las cookies en
                        publicidad</a>.
                    </li>
                </ul>
                <h2>Navegación Web</h2>
                <p>
                    Al navegar por el Sitio Web se pueden recoger datos no
                    identificativos, que pueden incluir, la
                    dirección IP, geolocalización, un registro de cómo se
                    utilizan los servicios y sitios, hábitos de
                    navegación y otros datos que no pueden ser utilizados para
                    identificarle.
                </p>
                <p>
                    El sitio Web utiliza los siguientes servicios de análisis de
                    terceros:
                </p>
                <ul>
                    <li>Google Analytics.</li>
                </ul>
                <p>
                    El Titular utiliza la información obtenida para obtener
                    datos estadísticos, analizar tendencias,
                    administrar el sitio, estudiar patrones de navegación y
                    para recopilar información demográfica.
                </p>
                <p>
                    El Titular no se hace responsable del tratamiento de los
                    datos personales que realicen las páginas
                    web a las que pueda acceder a través de los distintos
                    enlaces que contiene el Sitio Web.
                </p>
                <h2>Exactitud y veracidad de los datos personales</h2>
                <p>
                    Usted se compromete a que los datos facilitados al
                    Titular sean correctos, completos, exactos y
                    vigentes, así como a mantenerlos debidamente
                    actualizados.
                </p>
                <p>
                    Como Usuario del Sitio Web es el único responsable de la
                    veracidad y corrección de los datos
                    remitidos al Sitio Web exonerando al Titular de
                    cualquier responsabilidad al respecto.
                </p>
                <h2>Aceptación y consentimiento</h2>
                <p>
                    Como Usuario del Sitio Web declara haber sido informado
                    de las condiciones sobre protección de datos
                    de carácter personal, acepta y consiente el tratamiento
                    de los mismos por parte del Titular en la
                    forma y para las finalidades indicadas en esta Política
                    de Privacidad.
                </p>
                <p>
                    Para contactar con el Titular, suscribirse a un boletín
                    o realizar comentarios en este sitio Web
                    tiene que aceptar la presente Política de Privacidad.
                </p>
                <h2>Cambios en la Política de Privacidad</h2>
                <p>
                    El Titular se reserva el derecho a modificar la presente
                    Política de Privacidad para adaptarla a
                    novedades legislativas o jurisprudenciales, así como a
                    prácticas de la industria.
                </p>
                <p>
                    Estas políticas estarán vigentes hasta que sean
                    modificadas por otras debidamente publicadas.
                </p>
            </IonContent>
        </>
    );
}

export default Privacidad;