import React from 'react';
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonNavLink,
    IonButton
} from '@ionic/react';
import Terminos from "./terminos_uso";
import Cookies from "./cookies";
import Privacidad from "./privacidad";
import Info from "./info";

function Legal() {
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle>Aviso Legal</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-text-justify ion-padding">
                <h2>Aviso Legal</h2>
                <hr/>
                <h2>Identificación y Titularidad</h2>
                <p>
                    En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la
                    Sociedad de la
                    Información y Comercio Electrónico, el Titular expone sus datos identificativos:
                </p>
                <p>
                    <ul>
                        <li>
                            <b>Titular:</b>&nbsp;
                            Enrique Sánchez-Reboto García
                        </li>
                        <li>
                            <b>NIF:</b>&nbsp;08849592C
                        </li>
                        <li>
                            <b>Domicilio:</b>&nbsp;
                            Badajoz - España.
                        </li>
                        <li>
                            <b>Correo electrónico:</b>&nbsp;
                            info@buscapk.es
                        </li>
                        <li>
                            <b>Sitio Web:</b>&nbsp;
                            <a href="https://buscapk.es">https://buscapk.es</a>
                        </li>
                    </ul>
                </p>
                <h2>Finalidad</h2>
                <p>
                    La finalidad del Sitio Web es: Ayudar en la localización de un punto kilométrico de una
                    carretera de España
                </p>
                <h2>Condiciones de Uso</h2>
                <p>
                    La utilización del Sitio Web le otorga la condición de Usuario, e implica la aceptación
                    completa de
                    todas las cláusulas y condiciones de uso incluidas en las páginas:</p>
                <IonNavLink routerDirection="forward" component={() => <Cookies/>}>
                    <IonButton fill="clear">Cookies</IonButton>
                </IonNavLink>
                <br/>
                <IonNavLink routerDirection="forward" component={() => <Legal/>}>
                    <IonButton fill="clear">Aviso Legal</IonButton>
                </IonNavLink>
                <br/>
                <IonNavLink routerDirection="forward" component={() => <Privacidad/>}>
                    <IonButton fill="clear">Política de Privacidad</IonButton>
                </IonNavLink>
                <p>
                    Si no estuviera conforme con todas y cada una de estas cláusulas y condiciones absténgase de
                    utilizar el Sitio Web.
                </p>
                <p>
                    El acceso al Sitio Web no supone, en modo alguno, el inicio de una relación comercial con el
                    Titular.
                </p>
                <p>
                    A través del Sitio Web, el Titular le facilita el acceso y la utilización de diversos
                    contenidos que
                    el Titular y/o sus colaboradores han publicado por medio de Internet.
                </p>
                <p>
                    A tal efecto, está obligado y comprometido a NO utilizar cualquiera de los contenidos del
                    Sitio Web
                    con fines o efectos ilícitos, prohibidos en este Aviso Legal o por la legislación vigente,
                    lesivos
                    de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar,
                    sobrecargar, deteriorar o impedir la normal utilización de los contenidos, los equipos
                    informáticos
                    o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo
                    informático
                    propios o contratados por el Titular, de otros usuarios o de cualquier usuario de Internet.
                </p>
                <p>
                    El Titular no será responsable de las opiniones vertidas por los usuarios a través del
                    sistema de
                    comentarios, redes sociales u otras herramientas de participación, conforme a lo previsto en
                    la
                    normativa de aplicación.
                </p>
                <h2>Medidas de seguridad</h2>
                <p>
                    Los datos personales que facilite al Titular pueden ser almacenados en bases de datos
                    automatizadas
                    o no, cuya titularidad corresponde en exclusiva al Titular, que asume todas las medidas de
                    índole
                    técnica, organizativa y de seguridad que garantizan la confidencialidad, integridad y
                    calidad de la
                    información contenida en las mismas de acuerdo con lo establecido en la normativa vigente en
                    protección de datos.
                </p>
                <p>
                    No obstante, debe ser consciente de que las medidas de seguridad de los sistemas
                    informáticos en
                    Internet no son enteramente fiables y que, por tanto el Titular no puede garantizar la
                    inexistencia
                    de virus u otros elementos que puedan producir alteraciones en los sistemas informáticos
                    (software y
                    hardware) del Usuario o en sus documentos electrónicos y ficheros contenidos en los mismos
                    aunque el
                    Titular pone todos los medios necesarios y toma las medidas de seguridad oportunas para
                    evitar la
                    presencia de estos elementos dañinos.
                </p>
                <h2>Tratamiento de Datos Personales</h2>
                <p>
                    Puede consultar toda la información relativa al tratamiento de datos personales que recoge
                    el
                    Titular en la página de:</p> <IonNavLink routerDirection="forward" component={() => <Privacidad/>}>
                <IonButton fill="clear">Política de Privacidad</IonButton>
            </IonNavLink>
                <h2>Contenidos</h2>
                <p>
                    El Titular ha obtenido la información, el contenido multimedia y los materiales incluidos en
                    el
                    Sitio Web de fuentes que considera fiables, pero, si bien ha tomado todas las medidas
                    razonables
                    para asegurar que la información contenida es correcta, el Titular no garantiza que sea
                    exacta,
                    completa o actualizada. El Titular declina expresamente cualquier responsabilidad por error
                    u
                    omisión en la información contenida en las páginas del Sitio Web.
                </p>
                <p>
                    Queda prohibido transmitir o enviar a través del Sitio Web cualquier contenido ilegal o
                    ilícito,
                    virus informáticos, o mensajes que, en general, afecten o violen derechos del Titular o de
                    terceros.
                </p>
                <p>
                    Los contenidos del Sitio Web tienen únicamente una finalidad informativa y bajo ninguna
                    circunstancia deben usarse ni considerarse como oferta de venta, solicitud de una oferta de
                    compra
                    ni recomendación para realizar cualquier otra operación, salvo que así se indique
                    expresamente.
                </p>
                <p>
                    El Titular se reserva el derecho a modificar, suspender, cancelar o restringir el contenido
                    del
                    Sitio Web, los vínculos o la información obtenida a través del Sitio Web, sin necesidad de
                    previo
                    aviso.
                </p>
                <p>
                    El Titular no es responsable de los daños y perjuicios que pudieran derivarse de la
                    utilización de
                    la información del Sitio Web o de la contenida en las redes sociales del Titular.
                </p>
                <h2>Política de cookies</h2>
                <p>
                    Puede consultar toda la información relativa a la política de recogida y tratamiento de las
                    cookies
                    en la página de:
                </p>
                <IonNavLink routerDirection="forward" component={() => <Cookies/>}>
                    <IonButton fill="clear">Política de Cookies</IonButton>
                </IonNavLink>.
                <h2>Enlaces a otros sitios Web</h2>
                <p>
                    El Titular puede proporcionarle acceso a sitios Web de terceros mediante enlaces con la
                    finalidad
                    exclusiva de informar sobre la existencia de otras fuentes de información en Internet en las
                    que
                    podrá ampliar los datos ofrecidos en el Sitio Web.
                </p>
                <p>
                    Estos enlaces a otros sitios Web no suponen en ningún caso una sugerencia o recomendación
                    para que
                    usted visite las páginas web de destino, que están fuera del control del Titular, por lo que
                    el
                    Titular no es responsable del contenido de los sitios web vinculados ni del resultado que
                    obtenga al
                    seguir los enlaces.
                    Asimismo, el Titular no responde de los links o enlaces ubicados en los sitios web
                    vinculados a los
                    que le proporciona acceso.
                </p>
                <p>
                    El establecimiento del enlace no implica en ningún caso la existencia de relaciones entre el
                    Titular
                    y el propietario del sitio en el que se establezca el enlace, ni la aceptación o aprobación
                    por
                    parte del Titular de sus contenidos o servicios.
                </p>
                <p>
                    Si accede a un sitio web externo desde un enlace que encuentre en el Sitio Web usted deberá
                    leer la
                    propia política de privacidad del otro sitio web que puede ser diferente de la de este sitio
                    Web.
                </p>
                <h2>Propiedad intelectual e industrial</h2>
                <p>
                    Todos los derechos están reservados.
                </p>
                <p>
                    Todo acceso a este Sitio Web está sujeto a las siguientes condiciones: la reproducción,
                    almacenaje
                    permanente y la difusión de los contenidos o cualquier otro uso que tenga finalidad pública
                    o
                    comercial queda expresamente prohibida sin el consentimiento previo expreso y por escrito
                    del
                    Titular.
                </p>
                <h2>Limitación de responsabilidad</h2>
                <p>
                    El Titular declina cualquier responsabilidad en caso de que existan interrupciones o un mal
                    funcionamiento de los Servicios o contenidos ofrecidos en Internet, cualquiera que sea su
                    causa.
                    Asimismo, el Titular no se hace responsable por caídas de la red, pérdidas de negocio a
                    consecuencia
                    de dichas caídas, suspensiones temporales de fluido eléctrico o cualquier otro tipo de daño
                    indirecto que te pueda ser causado por causas ajenas a el Titular.
                </p>
                <p>
                    Antes de tomar decisiones y/o acciones con base a la información incluida en el Sitio Web,
                    el
                    Titular le recomienda comprobar y contrastar la información recibida con otras fuentes.
                </p>
                <h2>Jurisdicción</h2>
                <p>
                    Este Aviso Legal se rige íntegramente por la legislación española.
                </p>
                <h2>Contacto</h2>
                <p>
                    En caso de que usted tenga cualquier duda acerca de este Aviso Legal o quiera realizar
                    cualquier
                    comentario sobre el Sitio Web, puede enviar un mensaje de correo electrónico a la dirección:
                    info@buscapk.es
                </p>
                <IonNavLink routerDirection="forward" component={() => <Info/>}>
                    <IonButton>Volver a la página de información</IonButton>
                </IonNavLink>
            </IonContent>
        </>
    );
}

export default Legal;