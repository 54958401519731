import {IonButton, IonRow} from "@ionic/react";
import React from "react";
import writeToClipboard from "../utils/writeToClipboard";
import {t} from "i18next";


const CopyCoord = ({selectedKilometer}) => {
    return (
        <IonRow class="ion-justify-content-center">
            <IonButton size="small" color="primary" fill="outline"
                       onClick={() => writeToClipboard(selectedKilometer.location)}
                       target="_blank" rel="noopener noreferrer">{t('misc.copyCoord')}</IonButton>
        </IonRow>);
};

export default CopyCoord;