import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {headers, REST_BASE_URL} from "../constants";
import {
    IonList,
    IonItem,
    IonLabel,
    IonContent,
    IonNote,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonText,
    IonSelect,
    IonSelectOption, IonSearchbar
} from "@ionic/react";
import {convertToDMS} from "../utils/convertToDMS";
import {useTranslation} from "react-i18next";
import CopyCoord from "../components/CopyCoord";


const Tab3 = () => {
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [kilometers, setKilometers] = useState([]);
    const [selectedRoad, setSelectedRoad] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedKilometer, setSelectedKilometer] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(1); // Default to Spain


    useEffect(() => {
        if (searchValue.length >= 2) {
            const fetchRoads = async () => {
                try {
                    const response = await axios.get(`${REST_BASE_URL}roads/search/?name=${searchValue}&country=${selectedCountry}`, headers);
                    setSearchResults(response.data);
                } catch (error) {
                    console.error(error);
                }
            };

            fetchRoads();
        }
    }, [searchValue, selectedCountry]);

    useEffect(() => {
        if (selectedRoad) {
            axios.get(`${REST_BASE_URL}roads/posts_by_road/?road_id=${selectedRoad.id}`, headers)
                .then(response => {
                    setKilometers(response.data);
                }).catch((error) => {
                console.log(error.response.data);
            });
        }
    }, [selectedRoad]);

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleKilometerClick = (km) => {
        setSelectedKilometer(km);
        setShowModal(true);
    };

    const handleRoadClick = (road) => {
        if (selectedRoad && selectedRoad.id === road.id) {
            setSelectedRoad(null);
            setKilometers([]);
        } else {
            setSelectedRoad(road);
        }
    };

    return (
        <>
            <IonHeader>
                <IonItem color="primary">
                    <IonSelect
                        justify="start"
                        value={selectedCountry}
                        onIonChange={(e) => setSelectedCountry(e.detail.value)}
                    >
                        <div slot="label">
                            {t('search.selectcountry')}
                        </div>
                        <IonSelectOption value={1}>{t('search.spain')}</IonSelectOption>
                        <IonSelectOption value={2}>{t('search.france')}</IonSelectOption>
                        <IonSelectOption value={3}>{t('search.portugal')}</IonSelectOption>
                    </IonSelect>
                    <IonSearchbar
                        color="light"
                        value={searchValue} onIonInput={handleInputChange}
                        labelPlacement="floating" placeholder={t('search.introad')}
                        clearInput={true} maxlength={20}/>
                </IonItem>
            </IonHeader>

            <IonContent>
                {searchResults.length > 0 ? (<IonList>
                    {searchResults.map(road => (<div key={road.id}>
                        <IonItem button onClick={() => handleRoadClick(road)}>
                            <IonLabel>{road.name} / {road.owner}</IonLabel>
                        </IonItem>
                        {selectedRoad && selectedRoad.id === road.id && kilometers.length > 0 && (<IonList inset={true}>
                            {kilometers.map(km => (
                                <IonItem color="light" button onClick={() => handleKilometerClick(km)}
                                         key={km.id}>Pk {km.kilometer}, {km.direction}</IonItem>))}
                        </IonList>)}
                    </div>))}
                </IonList>) : (<IonNote>{t('search.noresult')}</IonNote>)}
                <IonModal class="pk-modal-search" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{t('misc.infoPK')}</IonTitle>
                            <IonButton slot="end" fill="clear" color="light"
                                       onClick={() => setShowModal(false)}>{t('misc.close')}</IonButton>
                        </IonToolbar>
                    </IonHeader>
                    {selectedKilometer && selectedRoad && (<IonContent>
                        <IonList class="centered-content">
                            <IonItem><IonText color="primary">
                                <h3>{selectedRoad.name},
                                    km {selectedKilometer.kilometer} ({selectedKilometer.direction})</h3>
                            </IonText></IonItem>
                            <div>
                                {selectedKilometer.location && ((() => {
                                    const [lng, lat] = selectedKilometer.location
                                        .replace("SRID=4326;POINT (", "")
                                        .replace(")", "")
                                        .split(" ")
                                        .map(parseFloat);
                                    return (<>
                                        <IonText color="primary">
                                            <h3>{convertToDMS(lat, 'latitude')}</h3>
                                            <h3>{convertToDMS(lng, 'longitude')}</h3>
                                        </IonText>
                                        <IonButton fill="clear" size="small"
                                                   href={`https://maps.google.com/maps?ll=${lat},${lng}=0.1,0.1&t=m&q=${lat},${lng}`}
                                                   target="_blank" rel="noopener noreferrer">
                                            <img src="/google-maps-144.webp" alt="Google Maps"
                                                 style={{width: '48px', height: '48px'}}/>
                                        </IonButton>
                                        <IonButton fill="clear" size="small"
                                                   href={`https://maps.apple.com/maps?ll=${lat},${lng}=0.1,0.1&t=m&q=${lat},${lng}`}
                                                   target="_blank" rel="noopener noreferrer">
                                            <img src="/mapa-de-apple-144.webp" alt="Apple Maps"
                                                 style={{width: '48px', height: '48px'}}/>
                                        </IonButton>
                                        <IonButton fill="clear" size="small"
                                                   href={`http://waze.com/ul?=${lat},${lng}=0.1,0.1&t=m&q=${lat},${lng}`}
                                                   target="_blank" rel="noopener noreferrer">
                                            <img src="/waze-144.webp" alt="Waze"
                                                 style={{width: '48px', height: '48px'}}/>
                                        </IonButton>
                                    </>);
                                })())}
                            </div>
                        </IonList>
                        <CopyCoord selectedKilometer={selectedKilometer}/>
                    </IonContent>)}
                </IonModal>
            </IonContent>
        </>);
};
export default Tab3;