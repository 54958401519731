import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact
} from '@ionic/react';
import {IonReactRouter} from "@ionic/react-router";
import {information, mapOutline, search} from "ionicons/icons";
import Tab1 from "./tabs/Tab1";
import Tab3 from "./tabs/Tab3";
import Tab4 from "./tabs/Tab4";
import {Route, Redirect} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import { Suspense } from 'react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

// /* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
//
// /* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import LanguageSelector from "./utils/languajeSelector";

setupIonicReact();

const App = () => {
    const {t, i18n} = useTranslation();
    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Redirect exact path="/" to="/map"/>
                        <Route path="/map" render={() => <Tab1/>} exact={true}/>
                        {/*<Route path="/search" render={() => <Tab2/>} exact={true}/>*/}
                        <Route path="/search" render={() => <Tab3/>} exact={true}/>
                        <Route path="/info" render={() => <Tab4/>} exact={true}/>
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom" style={{paddingBottom: '20px'}}>
                        <IonTabButton tab="map" href="/map">
                            <IonIcon icon={mapOutline}/>
                            <IonLabel>
                                {t('tabs.map')}
                            </IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="search" href="/search">
                            <IonIcon icon={search}/>
                            <IonLabel>
                                {t('tabs.search')}
                            </IonLabel>
                        </IonTabButton>

                        <IonTabButton tab="info" href="/info">
                            <IonIcon icon={information}/>
                            <IonLabel>
                                {t('tabs.info')}
                            </IonLabel>
                        </IonTabButton>

                        <IonTabButton id="language-alert">
                            <LanguageSelector/>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
};

// export default App;

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  );
}